/* eslint-disable no-unused-vars */
import { apolloClient } from "@/vue-apollo.js";
import router from "@/router";
import { sleep } from "@/plugins/utils";
import { getAppModeFromUrl } from "@/plugins/getAppModeFromUrl";
import { getLicences } from "@/plugins/licenceService";
import { getSeasons } from "../../plugins/seasonService";

export default {
  namespaced: true,

  state: {
    drawer: {
      active: true,
      color: "blue-dark",
    },
    appLoaded: false,
    appIsLoading: true,
    componentAppKey: 0,
    appUserMode: "licencie",
    appTitle: "",
  },

  getters: {
    drawer: (state) => state.drawer,
    appTitle: (state) => state.appTitle,
    appLoaded: (state) => state.appLoaded,
    appIsLoading: (state) => state.appIsLoading,
    componentAppKey: (state) => state.componentAppKey,
    appUserMode: (state) => state.appUserMode,
  },

  mutations: {
    TOGGLE_DRAWER(state) {
      state.drawer.active = !state.drawer.active;
    },
    SET_APP_LOADED(state, payload) {
      state.appLoaded = payload;
    },
    SET_APP_IS_LOADING(state, payload) {
      state.appIsLoading = payload;
    },
    SET_COMPONENT_APP_KEY(state, payload) {
      state.componentAppKey = payload;
    },

    SET_APP_USER_MODE(state, payload) {
      localStorage.setItem(process.env.VUE_APP_CURRENT_USER_MODE, payload);
      //console.log("commit user mode", payload);

      state.appUserMode = payload;
    },

    SET_APP_TITLE(state, payload) {
      // console.log('hello', payload)
      state.appTitle = payload;
    },
  },

  actions: {
    async initApp({ rootGetters, dispatch, commit }) {
      if (rootGetters["app/appLoaded"]) return;

      let userId = rootGetters["auth/currentUserID"];

      let response = await apolloClient.query({
        query: require("@/graphql/init.gql"),
        variables: {
          ID_Utilisateur: userId,
        },
      });

      let user = response.data.UTI_Utilisateurs[0];

      // Isolate structures of the current user for the store
      let userStructures = user.STR_StructureUtilisateurs;

      let saisons = await getSeasons({
        'pagination': false,
        'order[id]': 'desc',
      });

      let licences = null;

      if (userId) {
        licences = await getLicences({
          user: userId,
          partial: true,
          'groups[]': 'licence:read',
        });
      }

      await dispatch("saisons/initSaisons", saisons, { root: true });

      await dispatch("modules/initModules", {}, { root: true });

      await dispatch("basics/initList", {}, { root: true });

      await dispatch(
        "structure/initStructures",
        { structures: userStructures, userId: userId },
        { root: true }
      );

      await dispatch("user/initUser", user, { root: true });

      await dispatch("user/initLicence", licences, { root: true });

      await dispatch("setAppUserMode");
      // ...load all the app stuff
      commit("SET_APP_LOADED", true);
      commit("SET_APP_IS_LOADING", false);
      // console.log("APP READY");
      return;
    },

    async initPublicApp({ commit, dispatch }) {
      let saisons = await dispatch("saisons/getSaisons", {}, { root: true });
      await dispatch("saisons/initSaisons", saisons, { root: true });

      commit("SET_APP_LOADED", true);
      commit("SET_APP_IS_LOADING", false);
      // console.log("APP READY");
      return;
    },

    async setAppUserMode({ rootGetters, commit, dispatch }) {
      let hasRoleFederalOn = rootGetters["structure/withRoleFederal"].map(
        (str) => str.ID_Structure
      );

      let userMode = localStorage.getItem(
        process.env.VUE_APP_CURRENT_USER_MODE
      );

      let urlAppMode = getAppModeFromUrl();

      if (['licencie', 'federal'].includes(urlAppMode)) {
        userMode = urlAppMode;
      }

      // CIF IT's FOR USER FEDERAL
      if (hasRoleFederalOn.length) {
        await dispatch("user/setUserFeralStructure", hasRoleFederalOn, {
          root: true,
        });

        // CHECK IF LAST TIME IT WAS ON LICENCIE MOD

        if (userMode === "licencie") {
          await dispatch("structure/setCurrentStructureLicencie", true, {
            root: true,
          });
        } else {
          // userMode = "federal";
          await dispatch("structure/setCurrentStructureFederal", undefined, {
            root: true,
          });
        }

        console.log("LOCAL MODE:", userMode);
        commit("SET_APP_USER_MODE", userMode);
        return;
      }

      // MEANS IT's A BASIC USER SO LOAD THE LICENCIE MODE
      commit("SET_APP_USER_MODE", "licencie");
      return;
    },

    async updateAppUserMode({ commit, dispatch }, user_mode) {
      // if usr mode === fede => tset the good strucutre id
      await dispatch("setAppIsLoading", true);

      console.log("USER_MODE:", user_mode);

      if (user_mode == "licencie") {
        await dispatch("structure/setCurrentStructureLicencie", true, {
          root: true,
        });
      } else {
        await dispatch("structure/setCurrentStructureFederal", undefined, {
          root: true,
        });
      }

      if (user_mode === 'federal') {
        window.location.href = `${process.env.VUE_APP_FRONT_NEXT_URL}?appMode=${user_mode}`;

        return;
      }

      commit("SET_APP_USER_MODE", user_mode);

      if (router.currentRoute.name != "Home") {
        router.push({ name: "Home" });
      }

      await sleep(500);
      await dispatch("setAppIsLoading", false);
    },

    async updateAppUserModeThenRoute({ commit, dispatch }, payload) {
      // if usr mode === fede => tset the good strucutre id
      await dispatch("setAppIsLoading", true);

      if (payload.user_mode == "licencie") {
        await dispatch("structure/setCurrentStructureLicencie", true, {
          root: true,
        });
        console.log("ato eee :>> ");
      } else {
        await dispatch("structure/setCurrentStructureFederal", undefined, {
          root: true,
        });
      }
      commit("SET_APP_USER_MODE", payload.user_mode);
      if (payload.route) router.push({ name: payload.route });

      await sleep(500);
      await dispatch("setAppIsLoading", false);
    },

    async toggleUserMode({ dispatch, getters }) {
      let mode = getters.appUserMode == "federal" ? "licencie" : "federal";
      return await dispatch("updateAppUserMode", mode);
    },

    setAppIsLoading({ commit }, payload) {
      return commit("SET_APP_IS_LOADING", payload);
    },

    hydrateApp({ commit, getters }) {
      return commit("SET_COMPONENT_APP_KEY", getters.componentAppKey + 1);
    },

    toggleDrawer({ commit }) {
      commit("TOGGLE_DRAWER");
    },

    setAppTitle({ commit }, payload) {
      commit("SET_APP_TITLE", payload.title);
    },
  },
};
