import { apolloClient } from "@/vue-apollo";

const mutations = {
  UPDATE_ACCUEIL_ET_CONTACT(state, payload) {
    state.structures[payload.index].STR_Structure.INFO_AccueilHorraire =
      payload.data.INFO_AccueilHorraire;
    state.structures[payload.index].STR_Structure.INFO_Email =
      payload.data.INFO_Email;
    state.structures[payload.index].STR_Structure.INFO_Telephone =
      payload.data.INFO_Telephone;
  },

  UPDATE_INFO_PRES_STRUCTURE(state, payload) {
    state.structures[payload.index].STR_Structure.INFO_Presentation =
      payload.data.INFO_Presentation;
    state.structures[payload.index].STR_Structure.JUR_Siret =
      payload.data.JUR_Siret;
    state.structures[payload.index].STR_Structure.JUR_AgrementJeunesseSports =
      payload.data.JUR_AgrementJeunesseSports;
  },

  SET_STRUCTURE_ACTIVITE(state, payload) {
    state.structures[payload.index].ACTI_ActivitePratiquee = payload.data;
  },
};

const actions = {
  async getStructureActivite({ commit, getters }, payload) {
    let ID_Structure = payload ? payload : getters.currentStructureId;
    if (getters.structureIndex(ID_Structure).ACTI_ActivitePratiquee) {
      return getters.structureIndex(ID_Structure).ACTI_ActivitePratiquee;
    }

    let response = await apolloClient.query({
      query: require("@/graphql/Activites/get_activite_structure.gql"),
      fetchPolicy: "network-only",
      variables: {
        ID_Structure: ID_Structure,
      },
    });

    commit("SET_STRUCTURE_ACTIVITE", {
      index: getters.structureIndex(ID_Structure),
      data: response.data.ACTI_ActivitePratiquee,
    });
    console.log(getters.structureIndex(ID_Structure).ACTI_ActivitePratiquee);
    // return getters.structureIndex(ID_Structure).ACTI_ActivitePratiquee;
    return response.data?.ACTI_ActivitePratiquee;
  },
  async getActiviteDelegation() {
    const response = await apolloClient.query({
      query: require("@/graphql/Activites/get_activite_delegation.gql"),
      fetchPolicy: "network-only",
    });
    return response.data.activitytype;
  },
  async getStructureActiviteFromStrID({ ctx }, ID_Structure) {
    let response = await apolloClient.query({
      query: require("@/graphql/Activites/get_activite_structure.gql"),
      fetchPolicy: "network-only",
      variables: {
        ID_Structure: ID_Structure,
      },
    });
    return response.data.ACTI_ActivitePratiquee;
  },
  async getActivitieDelegation({ ctx }) {
    let response = await apolloClient.query({
      query: require("@/graphql/Activites/get_activite_delegation.gql"),
      fetchPolicy: "network-only",
    });
    return response.data.LIST_ActiviteType;
  },

  async updateAccueilEtContact({ commit, getters }, payload) {
    await apolloClient.mutate({
      mutation: require("@/graphql/Structures/get_structure_accueil_contact.gql"),
      variables: {
        id: payload.ID_Structure,
        INFO_AccueilHorraire: payload.INFO_AccueilHorraire,
        INFO_Email: payload.INFO_Email,
        INFO_Telephone: payload.INFO_Telephone,
      },
    });

    commit("UPDATE_ACCUEIL_ET_CONTACT", {
      index: getters.currentIndex,
      data: payload,
    });
  },

  async updateStructureActivite({ commit, getters }, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Activites/update_activite_structure.gql"),
      variables: {
        Activities: payload.Activites,
        ID_Structure: payload.ID_Structure,
      },
    });
    let data = response.data.insert_ACTI_ActivitePratiquee.returning;
    console.log(data);
    if (data) {
      commit("SET_STRUCTURE_ACTIVITE", {
        index: getters.structureIndex(payload.ID_Structure),
        data: data,
      });
      return data;
    }
    return;
  },

  async updatePresentationStructure({ commit, getters }, payload) {
    let activites = payload.activites.map((obj) =>
      Object.assign({
        ID_Structure: payload.structure.id,
        ID_Activite: obj.ID_Activite,
        EST_ActivitePrincipale: obj.EST_ActivitePrincipale,
      })
    );
    await apolloClient.mutate({
      mutation: require("@/graphql/Structures/update_structure_presentation.gql"),
      variables: {
        ID_Structure: payload.structure.id,
        INFO_Presentation: payload.structure.INFO_Presentation,
        JUR_Siret: payload.structure.JUR_Siret,
        JUR_AgrementJeunesseSports:
          payload.structure.JUR_AgrementJeunesseSports,
        CLUB_AcceuillantMineurAge:
          payload.affiliations.CLUB_AcceuillantMineurAge,
        CLUB_EST_AccepteChequesVacances:
          payload.affiliations.CLUB_EST_AccepteChequesVacances,
        CLUB_EST_AccepteCouponSport:
          payload.affiliations.CLUB_EST_AccepteCouponSport,
        CLUB_EST_AcceptePassSportCulture:
          payload.affiliations.CLUB_EST_AcceptePassSportCulture,
        CLUB_EST_AcceuillantHandicape:
          payload.affiliations.CLUB_EST_AcceuillantHandicape,
        CLUB_EST_AcceuillantMineur:
          payload.affiliations.CLUB_EST_AcceuillantMineur,
        CLUB_EST_ConventionAllocationFamilliale:
          payload.affiliations.CLUB_EST_ConventionAllocationFamilliale,
        CLUB_EST_PreparePasseport:
          payload.affiliations.CLUB_EST_PreparePasseport,
        CLUB_EST_PretMateriel: payload.affiliations.CLUB_EST_PretMateriel,
        CLUB_EST_HandiGuide: payload.affiliations.CLUB_EST_HandiGuide,
        Activities: activites,
        Disabilities: payload.disabilities,
      },
    });

    commit("UPDATE_INFO_PRES_STRUCTURE", {
      index: getters.currentIndex,
      data: payload.structure,
    });
    commit("SET_STRUCTURE_ACTIVITE", {
      index: getters.currentIndex,
      data: payload.activites,
    });
    commit("SET_STRUCTURE_AFFILIATION", {
      index: getters.currentIndex,
      data: payload.affiliations,
    });
    console.log("payload.activites", payload.activites);
  },

  async updateInfoPresentationStructure({ commit, getters }, payload) {
    await apolloClient.mutate({
      mutation: require("@/graphql/Structures/update_structure_info_presentation.gql"),
      variables: {
        ID_Structure: payload.structure.id,
        INFO_Presentation: payload.structure.INFO_Presentation,
      },
    });
    commit("UPDATE_INFO_PRES_STRUCTURE", {
      index: getters.currentIndex,
      data: payload.structure,
    });
  },

  async updateStructureAdministration({ commit, getters }, payload) {
    await apolloClient.mutate({
      mutation: require("@/graphql/Structures/update_structure_administration.gql"),
      variables: {
        id: payload.id,
        STR_data: payload.STR_data,
      },
    });
  },

  async getStructureDisabilities({ commit, getters }, ID_Structure) {
    let response = await apolloClient.query({
      query: require('@/graphql/Disability/getStructureDisabilities.gql'),
      variables: {
        id: ID_Structure,
      },
      fetchPolicy: 'network-only',
    });

    return response.data.STR_Structure_Disability;
  },
};

export { mutations, actions };
