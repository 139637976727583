<template>
  <div class="ffme__avatar d-flex justify-center align-center flex-column">
    <v-avatar
      :size="size"
      class="avatar-logo mt-4"
      v-bind:style="{ backgroundImage: 'url(' + avatarUrl + ')' }"
    >
      <!-- <img :src="avatarUrl" alt="Avatar image" /> -->
    </v-avatar>

    <div v-if="canEdit">
      <v-dialog v-model="dialog" persistent max-width="1000">
        <template v-slot:activator="{ on }">
          <span v-if="changeLink" v-on="on" class="ffme__avatar-change-link">
            <v-icon>mdi-camera</v-icon>
            <span>{{ textEditLink }}</span>
          </span>
        </template>

        <v-card>
          <v-card-title primary-title>
            <h2 v-if="textHeader" class="headline mb-0">{{ textHeader }}</h2>

            <ImageUpload
              :uploading="avatar_uploading"
              v-on:actionCancel="uploadCanceled"
              v-on:actionUpload="uploadDone"
              :textImageHint="textImageHint"
            />
          </v-card-title>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="showInfo" class="avatar__info text-center __font-small">
      <div v-if="configLink" class="avatar__config-link">
        <font-awesome-icon icon="cog" />
        <router-link :to="configLink.url">{{ configLink.title }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageUpload from "@/components/utils/image_upload.vue";
import { success_notification } from "@/plugins/notifications.js";

export default {
  name: "Avatar",

  components: { ImageUpload },

  props: {
    url: {
      type: String,
      default: "",
    },
    changeLink: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },

    showInfo: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },

    editable: {
      type: Boolean,
      default: false,
    },
    configLink: {
      type: Object,
      default: () => {},
    },
    textImageHint: {
      type: String,
      default: "",
    },
    textEditLink: {
      type: String,
      default: "Changer la photo de profil",
    },
    textHeader: {
      type: String,
      default: "Changer la photo de profil",
    },

    userData: {
      type: Object,
      default: null,
    },

    clubID: {
      type: Number,
      default: 0,
    },

    showAvatar: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    dialog: false,
    image_modified: "nope",
    raw_image_data: null,
    avatar_uploading: false,
    image_hint: "",
    edit_link: "",
    avatarUrl: "",
  }),

  async created() {
    // just in case initializing the data retrieval to make sure we get what we need later
    //  await this.getInformationsGenerales()
    await this.getListTypesDocument();
    this.setAvatarUrl();
  },

  computed: {
    ...mapGetters("user", ["currentUser", "currentUserAvatar"]),
    ...mapGetters("structure", ["currentStructureAvatar", "currentStructure"]),
    ...mapGetters("basics", [
      "getList",
      "getDocumentType",
      "getDocumentTypeAvatarUser",
    ]),

    canEdit() {
      return this.editable && !this.clubID;
    },
  },

  methods: {
    ...mapActions("basics", ["insertDocument", "getListTypesDocument"]),

    async uploadDone(data) {
      if (!this.canEdit) {
        return;
      }

      this.avatarUrl = ""; // make sure that the image is replaced, even if the image name didn't change
      this.avatar_uploading = true;
      let payload = {
        CheminDocument: data.CheminDocument,
        DocumentType: this.clubID
          ? this.getDocumentType("avatar_structure").id
          : this.getDocumentType("avatar_user").id,
      };
      if (!this.clubID && this.currentUserAvatar) {
        payload.id = this.currentUserAvatar.id;
        payload.NomDocument = "Avatar user " + this.currentUser.LOG_Login;
      }

      if (this.clubID && this.currentStructureAvatar) {
        payload.id = this.currentStructureAvatar.id;
        payload.NomDocument =
          "Avatar user " + this.currentStructure.STR_Structure.NomStructure;
      }

      if (this.clubID) {
        payload.clubID = this.clubID;
      } else {
        payload.ID_Utilisateur = this.currentUser.id;
      }
      await this.insertDocument(payload);

      this.avatarUrl = data.CheminDocument;
      this.dialog = false;
      // give the modal time to have its close animation before we reset the content
      setTimeout(() => {
        this.avatar_uploading = false;
      }, 500);
      success_notification("Profile image updated successfuly");
    },

    uploadCanceled() {
      this.dialog = false;
    },

    setAvatarUrl() {
      if (this.clubID) {
        this.avatarUrl = this.getAvatarStructureUrl();
      } else if (this.userData === null) {
        this.avatarUrl = this.getAvatarCurrentUserUrl();
      } else {
        this.avatarUrl = this.getAvatarUserUrl();
      }
    },

    getAvatarUserUrl() {
      let categorie_id = this.getDocumentTypeAvatarUser.id;
      if (this.userData.DOC_Documents) {
        let avatar = this.userData.DOC_Documents.find(
          (doc) => doc.ID_Type_Document == categorie_id
        );
        if (avatar) return avatar.CheminDocument;
      } else if (this.showAvatar === false) {
        return (
          "https://ui-avatars.com/api/?name=" +
          this.userData.CT_Nom +
          "+" +
          this.userData.CT_Prenom +
          "&size=256"
        );
      }
      return (
        "https://ui-avatars.com/api/?name=" +
        this.userData.LOG_Login +
        "&size=256"
      );
    },

    getAvatarCurrentUserUrl() {
      if (this.currentUserAvatar) {
        return this.currentUserAvatar.CheminDocument;
      } else if (this.showAvatar === false) {
        return (
          "https://ui-avatars.com/api/?name=" +
          this.userData.CT_Nom +
          "+" +
          this.userData.CT_Prenom +
          "&size=256"
        );
      }
      return (
        "https://ui-avatars.com/api/?name=" +
        this.currentUser.LOG_Login +
        "&size=256"
      );
    },
    getAvatarStructureUrl() {
      if (this.currentStructureAvatar) {
        return this.currentStructureAvatar.CheminDocument;
      }
      return "/img/logo-ffme-2018_transparent_couleur.8a1cdcf6.png";
    },
  },
  watch: {
    clubID() {
      this.setAvatarUrl();
    },
    userData() {
      this.setAvatarUrl();
    },
  },
};
</script>

<style scoped>
.headline.mb-0 {
  margin-left: 15px;
}
</style>
<style>
.avatar-logo {
  height: 140px;
  min-width: 140px;
  /* background-color: black; */
  background-size: contain;
  background-position: center;
  width: 140px;
}
</style>
