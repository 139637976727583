/* eslint-disable no-empty-pattern */
import { apolloClient } from "@/vue-apollo.js";
// import {error_notification} from '@/plugins/notifications'
import { Loader } from "google-maps";
import { error_notification } from "@/plugins/notifications.js";

import axios from "axios";

export default {
  namespaced: true,

  state: {
    LIST: {},
    DOC_Documents: [],

    googleLoader: null,
  },

  getters: {
    getList: (state) => {
      return state.LIST;
    },

    getNewsType: (state) => (type_slug) => {
      return state.LIST.LIST_NewsType.find(
        (news_type) => news_type.NomTypeNews === type_slug
      );
    },

    getNewsTypeBySlug: (state) => (type_slug) => {
      return state.LIST.LIST_NewsType.find(
        (news_type) => news_type.SlugTypeNews === type_slug
      );
    },

    getNewsTypeClub: (state, getters) => {
      return getters.getNewsType("club");
    },

    getNewsTypeFFME: (state, getters) => {
      return getters.getNewsType("ffme");
    },

    getDocuments: (state) => {
      return state.DOC_Documents;
    },

    getDocument: (state) => (id) => {
      return state.DOC_Documents.filter((doc) => doc.id === id);
    },
    getListTypesDocument: (state) => {
      return state.LIST.LIST_DocumentType;
    },

    getDocumentType: (state) => (type_slug) => {
      return state.LIST.LIST_DocumentType.find(
        (doc_type) => doc_type.SlugTypeDocument === type_slug
      );
    },

    getDocumentTypeAvatarUser: (state, getters) => {
      return getters.getDocumentType("avatar_user");
    },

    getDocumentTypeStructureUser: (state, getters) => {
      return getters.getDocumentType("avatar_structure");
    },

    getDocumentTypeImageNews: (state, getters) => {
      return getters.getDocumentType("image_news");
    },

    googleLoader: (state) => {
      return state.googleLoader;
    },
  },

  mutations: {
    SET_ALL_PAYS(state, pays) {
      state.LIST.ADR_Pays = pays;
    },

    SET_ALL_DOCUMENT_TYPES(state, doc_types) {
      state.LIST.LIST_DocumentType = doc_types;
    },

    SET_ALL_TYPE_NEWS(state, news) {
      state.LIST.LIST_NewsType = news;
    },

    SET_ALL_ASSURANCE_TYPE(state, assurance) {
      state.LIST.ASSU_AssuranceType = assurance;
    },

    SET_ALL_ASSURANCE_TYPE_CAT(state, assurance) {
      state.LIST.ASSU_AssuranceCategorie = assurance;
    },

    SET_ALL_ACTIVITE_TYPES(state, activite_types) {
      state.LIST.LIST_ActiviteType = activite_types;
    },

    SET_ALL_FONCTION_TYPE(state, fonction_types) {
      state.LIST.LIST_FonctionType = fonction_types;
    },

    SET_ALL_LICENCE_TYPE(state, licence_types) {
      state.LIST.LIST_LicenceType = licence_types;
    },

    SET_ALL_ETAPE_AFFILIATION(state, payload) {
      state.LIST.LIST_EtapeValidationAffiliation = payload;
    },

    SET_ALL_ETAPE_VALIDATION_LICENCE(state, payload) {
      state.LIST.LIST_EtapeValidationLicence = payload;
    },

    SET_ALL_COLLECTEUR_FORMATION_PROFESSIONNELLE(state, payload) {
      state.LIST.LIST_CollecteurFormationProfessionnelle = payload;
    },

    SET_ALL_ETAPE_VALIDATION_TARIF(state, payload) {
      state.LIST.LIST_EtapeValidationTarif = payload;
    },

    SET_LIBRARY_DATA(state, payload) {
      state.library_loaded[payload.libName] = payload.data;
    },

    SET_GOOGLE_LOADER(state, googleLoader) {
      state.googleLoader = googleLoader;
    },
  },

  actions: {
    async getLicenceById({ }, payload) {
      let query = {
        query: require("@/graphql/Licences/get_licence_by_id.gql"),
        variables: { id: payload.id },
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.UTI_Licence;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async initList({ dispatch }) {
      await dispatch("getListTypesDocument");
      return;
    },

    async getListTypesDocument({ commit, state }) {
      if (state.LIST.LIST_DocumentType) {
        return state.LIST.LIST_DocumentType;
      }

      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_type_document.gql"),
        fetchPolicy: "network-only",
      });
      let data = response.data.LIST_DocumentType;
      commit("SET_ALL_DOCUMENT_TYPES", data);
      return data;
    },

    async getListPays({ commit, state }) {
      if (state.LIST.ADR_Pays) {
        return state.LIST.ADR_Pays;
      }

      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_pays.gql"),
      });

      let data = response.data.ADR_Pays;
      commit("SET_ALL_PAYS", data);
      return data;
    },
    async getListPaysVoyage({ commit, state }) {
      if (state.LIST.ADR_Pays) {
        return state.LIST.ADR_Pays;
      }

      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_pays_voyage.gql"),
      });

      let data = response.data.ADR_Pays;
      console.log(`data=====>>>`, data);
      commit("SET_ALL_PAYS", data);
      return data;
    },

    async getListTypeNews({ commit, state }) {
      if (state.LIST.LIST_NewsType) {
        return state.LIST.LIST_NewsType;
      }

      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_type_news.gql"),
      });

      let data = response.data.LIST_NewsType;
      commit("SET_ALL_TYPE_NEWS", data);
      return data;
    },

    async getListTypeAssurances({ }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_type_assurance.gql"),
      });
      return response.data.ASSU_AssuranceType;
    },

    async getListTypeActivite() {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_type_activite.gql"),
        variables: {},
      });

      return response.data.activitytype;
    },

    async getListPratiqueType() {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_type_pratique.gql"),
        variables: {},
      });

      return response.data.LIST_PratiqueType;
    },

    async getListPratiqueNiveau() {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_niveau_pratique.gql"),
        variables: {},
      });

      return response.data.LIST_PratiqueNiveau;
    },

    async getListTypeFonctions({ commit, state }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_type_fonction_get.gql"),
      });
      let data = response.data.LIST_FonctionType;
      return data;
    },

    async getListTypeLicence({ }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_type_licence_get.gql"),
      });
      return response.data.LIST_LicenceType;
    },

    async getListTypeReseauxSociaux() {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_type_reseaux_sociaux.gql"),
      });
      return response.data.LIST_UrlType;
    },

    async getListEtapeAffiliation({ state, commit }) {
      if (state.LIST.LIST_EtapeValidationAffiliation) {
        return state.LIST.LIST_EtapeValidationAffiliation;
      }

      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_etapes_affiliation.gql"),
      });
      commit(
        "SET_ALL_ETAPE_AFFILIATION",
        response.data.LIST_EtapeValidationAffiliation
      );
      return response.data.LIST_EtapeValidationAffiliation;
    },

    async getListEtapeLicence({ commit, state }) {
      if (state.LIST.LIST_EtapeValidationLicence) {
        return state.LIST.LIST_EtapeValidationLicence;
      }

      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_type_validation_licence.gql"),
      });
      let data = response.data.LIST_EtapeValidationLicence;
      commit("SET_ALL_ETAPE_VALIDATION_LICENCE", data);
      return data;
    },

    async getListCollecteurFormationProfessionnelle({ state, commit }) {
      if (state.LIST.LIST_CollecteurFormationProfessionnelle) {
        return state.LIST.LIST_CollecteurFormationProfessionnelle;
      }

      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_collecteur_formation_professionnelle.gql"),
      });
      commit(
        "SET_ALL_COLLECTEUR_FORMATION_PROFESSIONNELLE",
        response.data.LIST_CollecteurFormationProfessionnelle
      );
      return response.data.LIST_CollecteurFormationProfessionnelle;
    },

    async getListEtapeValidationTarif({ state, commit }) {
      if (state.LIST.LIST_EtapeValidationTarif) {
        return state.LIST.LIST_EtapeValidationTarif;
      }

      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_etape_validation_tarif.gql"),
      });
      commit(
        "SET_ALL_ETAPE_VALIDATION_TARIF",
        response.data.LIST_EtapeValidationTarif
      );
      return response.data.LIST_EtapeValidationTarif;
    },
    async insertDocumentNormal(_, payload) {
      let mutation = {
        mutation: require("@/graphql/Documents/insert_document.gql"),
        variables: {
          newDoc: payload,
        },
      };
      const response = await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
      console.log("response :>> ", response);
      return response.insert_DOC_Document.returning[0];
    },

    // async insertDocument ( {  commit, getters, rootGetters }, payload ) {

    //     let newDoc = {
    //         CheminDocument: payload.CheminDocument,
    //         NomDocument: payload.NomDocument,
    //         ID_Type_Document: payload.ID_Type_Document
    //     }

    //     //GET the ID to know if it's update or not
    //     // ALso check if i's for an avatar or a news bc we don't want to store all of them
    //     if( getters.getDocumentTypeAvatarUser.id == newDoc.ID_Type_Document ||
    //         getters.getDocumentTypeStructureUser.id == newDoc.ID_Type_Document ||
    //         getters.getDocumentTypeImageNews.id ==  newDoc.ID_Type_Document
    //     ) {
    //         if(payload.ID_Document) {
    //             newDoc.id = payload.ID_Document
    //         }
    //     }

    //     // ID_Structure
    //     if (payload.ID_Structure && payload.ID_Structure !== false) {
    //         newDoc.ID_Structure = payload.ID_Structure
    //     } else {
    //         newDoc.ID_Structure = null
    //     }

    //     // ID_Utilisateur
    //     if (payload.ID_Utilisateur && payload.ID_Utilisateur !== false) {
    //         newDoc.ID_Utilisateur = payload.ID_Utilisateur
    //     } else {
    //         newDoc.ID_Utilisateur = null
    //     }

    //     // DateFinValiditeDocument
    //     if (payload.DateFinValiditeDocument) {
    //         newDoc.DateFinValiditeDocument = payload.DateFinValiditeDocument
    //     } else {
    //         newDoc.DateFinValiditeDocument = null
    //     }

    //     if (payload.Categorie && payload.Categorie !== false) { // add a check for a valid category id instead
    //         newDoc.Categorie = payload.Categorie
    //     } else {
    //         newDoc.Categorie = null
    //     }

    //     //console.log('BEFORE SAVE DOC', newDoc)
    //     let response
    //     if(newDoc.id) {
    //         response = await apolloClient.mutate({
    //             mutation: require('@/graphql/Documents/update_document.gql'),
    //             variables : {
    //                 ID_Document: newDoc.id,
    //                 CheminDocument: newDoc.CheminDocument,
    //                 NomDocument: newDoc.NomDocument,
    //             }
    //         })
    //     }else {
    //         response = await apolloClient.mutate({
    //             mutation: require('@/graphql/Documents/insert_document.gql'),
    //             variables : { newDoc: newDoc }
    //         })
    //     }

    //     let mutation = newDoc.id ? 'update_DOC_Document' : 'insert_DOC_Document'

    //     if (response.data[mutation].returning) {

    //         let data = response.data[mutation].returning[0]

    //         if (data) {

    //             if (data.ID_Structure) {
    //                 commit('structure/INSERT_DOCUMENT', data, { root: true })
    //             }
    //             if (data.ID_Utilisateur) {
    //                 // check if it is a medical document
    //                 let izCertificat = false
    //                 let slug = 'certificat_medical'
    //                 if ( data.LIST_DocumentType && data.LIST_DocumentType.SlugTypeDocument) {
    //                     izCertificat = data.LIST_DocumentType.SlugTypeDocument.substr(0, slug.length) === slug
    //                 }

    //                 if (izCertificat) {
    //                   if(payload.ID_Utilisateur === rootGetters['user/currentUserId']) {
    //                     commit('user/INSERT_CERTIFICAT_MEDICAUX', data, { root: true })
    //                   }
    //                 } else {
    //                     commit('user/INSERT_DOCUMENT', data, { root: true })
    //                 }
    //             }

    //             return data
    //         }

    //         return response
    //     }

    //     error_notification('insertDocument: problem saving to database')
    //     return response

    // },

    async updateDocumentKyc({ }, payload) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Documents/update_document.gql"),
        variables: {
          ID_Document: payload.ID_Document,
          CheminDocument: payload.CheminDocument,
          NomDocument: payload.NomDocument,
        },
      }).then((response) => {
        return response;
      });
      console.log('updateDocumentKyc resp', response)
      return response;
    },

    async insertDocument({ }, payload) {
      let variables = {
        CheminDocument: payload.CheminDocument,
        NomDocument: payload.NomDocument,
        ID_Type_Document: payload.DocumentType?.id || payload.DocumentType || payload.ID_Type_Document,

        ID_Structure: payload.ID_Structure,
        ID_Utilisateur: payload.ID_Utilisateur,
        ID_News: payload.ID_News,

        DateFinValiditeDocument: payload.DateFinValiditeDocument,
        DateValidation: payload.DateValidation,

        EST_Actif: payload.EST_Actif,
        EST_DocumentValide: payload.EST_DocumentValide,

        Categorie: payload.Categorie,
      };

      if (payload.ID_Document) {
        variables.id = payload.ID_Document;
      }

      if (payload.status) {
        variables.status = payload.status;
      }

      if (payload.ID_Saison) variables.ID_Saison = payload.ID_Saison;

      console.log(variables);
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Documents/insert_document2.gql"),
        variables: {
          document: variables,
        },
      });
      return response.data.insert_DOC_Document.returning[0];
    },
    async insertTemplate({ }, payload) {
      let variables = {
        CheminDocument: payload.CheminDocument,
        NomDocument: payload.NomDocument,
        SlugNomTemplate: payload.SlugNomTemplate,
        NomTemplate: payload.NomTemplate,
        ID_Saison: payload.ID_Saison,
      };

      // console.log(variables);
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Templates/insert_Template.gql"),
        variables: {
          template: variables,
        },
      });
      // console.log(response);
      if (response.data.insert_LIST_Template.returning[0]) {
        return response.data.insert_LIST_Template.returning[0];
      }
    },

    async deleteTemplate({ }, payload) {
      // let variables = {
      //   ID_Saison: payload.ID_Saison,
      //   NomTemplate: payload.NomTemplate,
      // };
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Templates/delete_template.gql"),
        variables: {
          ID_Saison: payload.ID_Saison,
          NomTemplate: payload.NomTemplate,
        },
      });
      return response;
    },

    async getTemplates({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Templates/get_templates.gql"),
        variables: {
          ID_Saison: payload.ID_Saison,
        },
      });
      let data = response.data.LIST_Template;
      return data;
    },

    async getTemplatesBySlug({ }, payload) {
      const headers = {
        "Content-Type": "application/pdf",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Disposition": "inline",
      };
      let response = await apolloClient.query({
        query: require("@/graphql/Templates/get_templates_slug.gql"),
        variables: {
          ID_Saison: payload.ID_Saison,
          SlugNomTemplate: payload.SlugNomTemplate,
        },
        headers: headers,
      });
      let data = response.data.LIST_Template;
      return data;
    },

    async getTemplateByName({ }, payload) {
      console.log("payload getTemplateByName", payload);
      let response = await apolloClient.query({
        query: require("@/graphql/Templates/getTemplateByName.gql"),
        variables: {
          ID_Saison: payload.ID_Saison,
          SlugNomTemplate: payload.SlugNomTemplate,
        },
      });
      let data = response.data.LIST_Template;
      return data;
    },

    async getDocument({ }, id) {
      let response = await apolloClient.query({
        query: require("@/graphql/Documents/get_document.gql"),
        variables: {
          ID_Document: id,
        },
      });
      let data = response.data.DOC_Document[0];
      return data;
    },

    async setGoogleLoader({ commit }) {
      let loader = new Loader(process.env.VUE_APP_GOOGLE_PLACE_API_KEY, {
        libraries: ["places"],
      });
      console.log(`object`, loader);
      commit("SET_GOOGLE_LOADER", loader);
      return;
    },

    async getListTarifCategorie({ }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_tarif_categorie.gql"),
      });
      return response.data.LIST_TarifCategorie;
    },

    async getListStructureType({ }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_structure_type.gql"),
      });
      return response.data.LIST_StructureType;
    },

    async insertNewFamilleTarif({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Tarifs/insert_tarif_famille.gql"),
        variables: {
          tarif: payload.tarif,
        },
        update: (store, { data: { insert_MKP_TarifFamille } }) => {
          let query = {
            query: require("@/graphql/Tarifs/get_tarifs_famille_structure.gql"),
            variables: {
              ID_Structure: insert_MKP_TarifFamille.returning[0].ID_Structure,
            },
          };
          try {
            const data = store.readQuery(query);
            if (data) {
              data.MKP_TarifFamille.push(
                insert_MKP_TarifFamille.returning[[0]]
              );
              store.writeQuery({ ...query, data: data });
            }
          } catch (e) {
            console.log("error on insert new familly cache");
          }
        },
      };

      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getListStructureCT({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_list_structure_ct.gql"),
      });
      return response.data.STR_Structure;
    },
    async getListStructureCTByLigue({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_list_structure_ct_by_ligue.gql"),
        variables: { ID_Ligue: payload.ID_Ligue },
      });
      return response.data.STR_Structure;
    },

    async getListStructureLigue({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_list_structure_ligue.gql"),
      });
      return response.data.STR_Structure;
    },

    async getInfoGenerales({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/InfoGenerales/get_info_generale.gql"),
      });

      return response.data.INFO_General;
    },

    async getDepartementsList({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_departement_list.gql"),
      });

      return response.data.ADR_Departement;
    },

    async getRegionList({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_list_region.gql"),
      });

      return response.data.LIST_GeoRegion;
    },

    async getTarifsCategorieFFME({ }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarifs_categorie_FFME.gql"),
      });

      return response.data.LIST_TarifCategorie;
    },

    async getTarifsFFME({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarifs_FFME.gql"),
        variables: payload,
        fetchPolicy: "network-only",
      });

      return response.data.MKP_Tarif;
    },

    async getTarifsByID({ }, payload) {
      console.log("payload", payload);
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarifs_by_Id.gql"),
        variables: {
          ID_Tarif: payload,
        },
        fetchPolicy: "network-only",
      });

      return response.data.MKP_Tarif;
    },

    async getDepartementByStructure({ }, payload) {
      console.log("payload", payload);
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_departement_list_by_structure.gql"),
        variables: {
          ID_Structure: payload,
        },
        fetchPolicy: "network-only",
      });

      return response.data?.ADR_Departement[0];
    },

    async insertTarifs({ }, payload) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Tarifs/insert_tarifs.gql"),
        variables: payload,
      });

      return response.data.MKP_Tarif;
    },

    async deleteTarifs({ }, payload) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Tarifs/delete_tarifs.gql"),
        variables: { ID_Saison: payload.ID_Saison, tarifs: payload.tarifs },
      });

      return response;
    },

    async getTarifsCategorieCT({ }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarifs_categorie_CT.gql"),
      });

      return response.data.LIST_TarifCategorie;
    },

    async getTarifsCategorieLigue({ }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarifs_categorie_Ligue.gql"),
      });

      return response.data.LIST_TarifCategorie;
    },

    async getTarifsCategorieClub({ }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarifs_categorie_club.gql"),
      });

      return response.data.LIST_TarifCategorie;
    },

    async getTarifsCT({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarifs_CT.gql"),
        variables: payload,
        fetchPolicy: "network-only",
      });

      return response.data.MKP_Tarif;
    },

    async getTarifsLigue({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarifs_Ligue.gql"),
        variables: payload,
        fetchPolicy: "network-only",
      });

      return response.data.MKP_Tarif;
    },

    async getCurrentClubTarif({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarifs_club.gql"),
        variables: payload,
        fetchPolicy: "network-only",
      });

      return response.data.MKP_Tarif;
    },

    async getCurrentAffiliationTarif({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarif_affiliation.gql"),
        variables: payload,
        fetchPolicy: "network-only",
      });

      return response.data.MKP_Tarif;
    },

    async getTarifsByCategoryId({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Listes/get_tarif_by_category_id.gql"),
        variables: payload,
        fetchPolicy: "network-only",
      });

      return response.data.MKP_Tarif;
    },

    async insertNewLetter({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/News/insert_news_letters.gql"),
        variables: {
          EST_Actif: payload.EST_Actif,
          NomNewsletter: payload.NomNewsletter,
          Ordre: payload.Ordre,
          Description: payload.Description,
          Frequence: payload.Frequence,
        },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          console.log("response", response);
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async insertNewLetterUser({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/News/insert_news_letter_utilisateur.gql"),
        variables: {
          newslettertype: payload.newslettertype,
        },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          console.log("response", response);
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getListDisabilities() {
      let mutation = {
        mutation: require("@/graphql/Disability/list.gql"),
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response.data.LIST_Disability;
        })
        .catch((e) => {
        });
    },

    async updateLicenceStatus({ }, payload) {
      return axios
        .post(
          `${process.env.VUE_APP_FFME_BACK_URL}/api/licences/${payload.id}/update-status`,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
            }
          },
        );
    }
  },
};
