/* eslint-disable no-empty-pattern */
/* eslint-disable no-unexpected-multiline */
import { apolloClient } from "@/vue-apollo";

const mutations = {
  SET_STRUCTURE_AFFILIATION(state, payload) {
    state.structures[payload.index].STR_Affiliation = payload.data;
  },

  UPDATE_STRUCTURE_SIRET(state, payload) {
    state.structures[payload.index].STR_Structure.JUR_Siret = payload.data;
  },

  UPDATE_STRUCTURE_AGREMENT_JEUNESS_SPORT(state, payload) {
    state.structures[payload.index].STR_Structure.JUR_AgrementJeunesseSports =
      payload.data;
  },

  UPDATE_AFFILIATION_ETAPE(state, payload) {
    state.structures[payload.index].STR_Affiliation.ID_EtapeAffiliation =
      payload.data;
  },

  UPDATE_AFFILIATION_STATUS(state, payload) {
    state.structures[payload.index].STR_Affiliation.DOC_Status =
      payload.data.DOC_Status;
  },

  UPDATE_AFFILIATION_COMPTE_RENDU_AG(state, payload) {
    state.structures[payload.index].STR_Affiliation.DOC_CompteRenduAg =
      payload.data.DOC_CompteRenduAg;
  },

  UPDATE_AFFILIATION_RAPPORT_TRESORERIE(state, payload) {
    state.structures[payload.index].STR_Affiliation.DOC_RapportTresorerie =
      payload.data.DOC_RapportTresorerie;
  },

  SET_STRUCTURE_AFFILIATION_TARIF(state, payload) {
    if (!state.structures[payload.index].MKP_Tarif)
      state.structures[payload.index].MKP_Tarif = [];
    state.structures[payload.index].MKP_Tarif = [
      ...state.structures[payload.index].MKP_Tarif,
      ...payload.data,
    ];
  },

  UPDATE_AFFILIATION_TARIF_STRUCTURE(state, payload) {
    let current_tarif = state.structures[payload.index].MKP_Tarif.find(
      (tarif) => tarif.id == payload.data.id
    );
    if (!current_tarif) {
      state.structures[payload.index].MKP_Tarif.push(payload.data);
    } else {
      let index = state.structures[payload.index].MKP_Tarif.indexOf(
        current_tarif
      );
      state.structures[payload.index].MKP_Tarif[index] = payload.data;
    }
  },

  DELETE_AFFILIATION_TARIF_STRUCTURE(state, payload) {
    let current_tarif = state.structures[payload.index].MKP_Tarif.find(
      (tarif) => tarif.id == payload.data.id
    );
    let index = state.structures[payload.index].MKP_Tarif.indexOf(
      current_tarif
    );
    state.structures[payload.index].MKP_Tarif.splice(index, 1);
  },

  UPDATE_AFFILIATION_ETP(state, payload) {
    state.structures[payload.index].STR_Affiliation.SAL_EquivalentTempsPlein =
      payload.data.SAL_EquivalentTempsPlein;
  },

  UPDATE_AFFILIATION_COLLECTEUR_PRO(state, payload) {
    state.structures[
      payload.index
    ].STR_Affiliation.ID_CollecteurFormationProfessionnelle =
      payload.data.ID_CollecteurFormationProfessionnelle;
  },
};

const actions = {
  async getLastAffiliationStructure({ }, { ID_Structure }) {
    let lastAffi = await apolloClient.mutate({
      mutation: require("@/graphql/Affiliations/get_last_affiliation_structure.gql"),
      variables: {
        ID_Structure,
      },
    });
    return lastAffi.data.STR_Affiliation;
  },
  async insertStructureAffiliation({ }, payload) {
    // get last affiliation and copy the reult for the one
    let lastAffi = await apolloClient.query({
      query: require("@/graphql/Affiliations/get_last_affiliation_structure.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
      },
      fetchPolicy: "network-only",
    });

    let data = lastAffi.data.STR_Affiliation;

    if (data[0].ID_Saison == payload.ID_Saison) {
      return data[0];
    }

    let newAffi = {};

    if (payload?.id) {
      newAffi.id = payload?.id;
    }

    if (data.length) {
      newAffi = data[0];
      newAffi.ID_Structure = payload.ID_Structure;
      newAffi.ID_Saison = payload.ID_Saison;
      newAffi.ID_EtapeAffiliation = payload.ID_ETAPE_Affiliation || 1;
      delete newAffi.__typename;
      delete newAffi.id;
    } else {
      newAffi.ID_Structure = payload.ID_Structure;
      newAffi.ID_Saison = payload.ID_Saison;
    }

    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Affiliations/insert_affiliation_structure.gql"),
      variables: { newAffi: newAffi },
    });

    return response.data.insert_STR_Affiliation.returning[0];
  },

  async getStructureAffiliation({ dispatch }, payload) {
    console.log('getStructureAffiliation', payload)
    let response = await apolloClient.query({
      query: require("@/graphql/Affiliations/get_affiliation_structure.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
        ID_Saison: payload.ID_Saison,
      },
      fetchPolicy: "network-only",
    });
    return response.data.affiliation[0] ?? false;
  },
  async getStructureAffiliations({ dispatch }, payload) {
    let response = await apolloClient.query({
      query: require("@/graphql/Affiliations/get_affiliations_structure.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
        seasons: payload.seasons,
      },
      fetchPolicy: "network-only",
    });
    let data = response.data.STR_Structure[0] ?? false;
    console.log("data !!!!!!!!!", data);
    return data;
  },
  async getSeasonLicence({ dispatch }, payload) {
    let response = await apolloClient.query({
      query: require("@/graphql/Licences/GetSeasonLicence.gql"),
      variables: {
        whereClause: payload.where,
      },
      fetchPolicy: "network-only",
    });
    let data = response.data.UTI_Licence;
    return data;
  },
  async GetLicenceByAge({ dispatch }, payload) {
    console.log("GetLicenceByAge :::::: ", payload);
    let response = await apolloClient.query({
      query: require("@/graphql/Structures/get_Licence_ByAge.gql"),
      variables: {
        ID_Saison: payload.ID_Saison,
        YearAge: payload.YearAge,
      },
      fetchPolicy: "network-only",
    });
    let data = response.data.UTI_Licence;
    console.log("data !!!!!!!!!", data);
    return data;
  },
  async getOnlyStructureAffiliation({ }, payload) {
    let response = await apolloClient.query({
      query: require("@/graphql/Affiliations/get_affiliation_structure.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
        ID_Saison: payload.ID_Saison,
      },
      fetchPolicy: "network-only",
    });
    let data = response.data.STR_Affiliation;

    return data;
  },

  async updateAffiliationStrucure({ }, payload) {
    // console.log("payload", payload.affiliation)
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Affiliations/update_base_affiliation_structure.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
        // ID_Saison: payload.ID_Saison,
        affiliation: payload.affiliation,
        Disabilities: payload.disabilities,
      },
    });
    return response;
  },

  async updateStatusAffiliation({ }, payload) {
    // console.log("payload", payload.affiliation)
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Affiliations/update_status_affiliation_structure.gql"),
      variables: {
        value: payload,
      },
    });
    return response;
  },

  async updateAffiliationInformationGeneral(
    { commit, getters, dispatch },
    payload
  ) {
    // Prepare array of new fonction to setup
    let fonctions = [
      {
        name: "president",
        ID_Fonction: payload.ID_FonctionPresident,
        ID_Utilisateur: payload.ID_newUserPresident,
      },
      {
        name: "secretaire",
        ID_Fonction: payload.ID_FonctionSecretaire,
        ID_Utilisateur: payload.ID_newUserSecretaire,
      },
      {
        name: "tresorier",
        ID_Fonction: payload.ID_FonctionTresorier,
        ID_Utilisateur: payload.ID_newUserTresorier,
      },
    ];

    fonctions.every(async (fct) => {
      // Check for each, if a new fonction user exist, and also if it's a different one
      // Maybe we don't need to do that
      if (fct.name == "president") {
        if (!payload.ID_newUserPresident) return;
        if (payload.ID_newUserPresident == payload.ID_CurrentUserPresident)
          return;
      }
      if (fct.name == "secretaire") {
        if (!payload.ID_newUserSecretaire) return;
        if (payload.ID_newUserSecretaire == payload.ID_CurrentUserSecretaire)
          return;
      }
      if (fct.name == "tresorier") {
        if (!payload.ID_newUserTresorier) return;
        if (payload.ID_newUserTresorier == payload.ID_CurrentUserTresorier)
          return;
      }

      // Delete the fonction of the old user
      let deletedUser = await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/delete_utilisateur_structure_fonction.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
          ID_Fonction: fct.ID_Fonction,
        },
        update: (store, { data: { update_STR_StructureUtilisateur } }) => {
          //console.log(update_STR_StructureUtilisateur)
          let query = {
            query: require("@/graphql/Utilisateurs/get_users_in_structure.gql"),
            variables: {
              ID_Structure: payload.ID_Structure,
            },
          };
          const data = store.readQuery(query);
          // console.log('here', data.STR_StructureUtilisateur)
          let userReturning = update_STR_StructureUtilisateur.returning[0];
          let userIndex = data.STR_StructureUtilisateur.indexOf(
            data.STR_StructureUtilisateur.find(
              (usr) => usr.ID_Utilisateur == userReturning.ID_Utilisateur
            )
          );
          data.STR_StructureUtilisateur[userIndex].ID_Fonction = null;
          store.writeQuery({ ...query, data: data });
        },
      });

      deletedUser =
        deletedUser.data.update_STR_StructureUtilisateur.returning[0];
      if (deletedUser) {
        // console.log('del', deletedUser)
        commit("REMOVE_USER_FONCTION_ON_STRUCTURE", {
          index: getters.structureIndex(deletedUser.ID_Structure),
          data: { ID_Utilisateur: deletedUser.ID_Utilisateur },
        });
      }

      // Set the new fonction to new user
      let user = await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_utilisateur_structure_fonction.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
          ID_Fonction: fct.ID_Fonction,
          ID_Utilisateur: fct.ID_Utilisateur,
          FonctionDescription: "",
        },
        // update: ( store, {}) {

        //   let query = {
        //       query: require("@/graphql/Utilisateurs/get_users_in_structure.gql"),
        //       variables: {
        //         ID_Structure: payload.ID_Structure,
        //         //fetchPolicy: "network-only"
        //       }
        //   }
        // }
      });

      // Comit the rezsult
      if (user.data.update_STR_StructureUtilisateur.returning[0]) {
        let data = user.data.update_STR_StructureUtilisateur.returning[0];
        commit("UPDATE_USER_FONCTION_ON_STRUCTURE", {
          index: getters.structureIndex(data.ID_Structure),
          data: data,
        });
      }
    });

    // update the affiliation
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Affiliations/update_affiliation_structure.gql"),
      variables: {
        ID_Affiliation: payload.ID_Affiliation,
        ID_Structure: payload.ID_Structure,
        ID_Saison: payload.ID_Saison,

        ID_EtapeAffiliation: payload.ID_EtapeAffiliation,

        ID_AdresseCorrespondance: payload.ID_AdresseCorrespondance,
        ID_NomCorrespondant: payload.ID_NomCorrespondant,
        JUR_Siret: payload.JUR_Siret,
        JUR_AgrementJeunesseSports: payload.JUR_AgrementJeunesseSports,
      },
    });
    //let dataAffiliation = response.data.update_STR_Affiliation.returning[0];

    let dataStructure = response.data.update_STR_Structure.returning[0];
    // Update state structure
    await dispatch("updateStructureAdresseCorrespondant", {
      id: dataStructure.ID_AdresseCorrespondance,
      ID_Structure: dataStructure.id,
    });
    commit("UPDATE_STRUCTURE_NOM_CORRESPONDANT", {
      index: getters.structureIndex(dataStructure.id),
      data: { id: dataStructure.ID_NomCorrespondant },
    });
    commit("UPDATE_STRUCTURE_SIRET", {
      index: getters.structureIndex(dataStructure.id),
      data: dataStructure.JUR_Siret,
    });
    commit("UPDATE_STRUCTURE_AGREMENT_JEUNESS_SPORT", {
      index: getters.structureIndex(dataStructure.id),
      data: dataStructure.JUR_AgrementJeunesseSports,
    });

    // update state affiliation
    // commit("UPDATE_AFFILIATION_ETAPE", {
    //   index: getters.structureIndex(dataAffiliation.ID_Structure),
    //   data: dataAffiliation.ID_EtapeAffiliation,
    // });
    return;
  },

  async updateAffiliationETPStructure({ }, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Affiliations/update_affiliation_structure_etp.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
        ID_Saison: payload.ID_Saison,
        SAL_EquivalentTempsPlein: payload.SAL_EquivalentTempsPlein,
      },
    });
    return response.data.update_STR_Affiliation.returning[0];
  },

  async updateAffiliationCollecteurFormationPro({ }, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Affiliations/update_affiliation_structure_collecteur_formation_pro.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
        ID_Saison: payload.ID_Saison,
        ID_CollecteurFormationProfessionnelle:
          payload.ID_CollecteurFormationProfessionnelle,
      },
    });
    return response.data.update_STR_Affiliation.returning[0];
  },

  async updateAffiliationDocument({ }, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Affiliations/update_doc_" +
        payload.GQLType +
        ".gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
        ID_Saison: payload.ID_Saison,
        ID_Doc: payload.ID_Doc,
      },
    });
    return response.data.update_STR_Affiliation.returning[0];
  },

  async getAffiliationTarifStructure({ }, payload) {
    let response = await apolloClient.query({
      query: require("@/graphql/Tarifs/get_tarifs_affiliation_structure.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
      },
      fetchPolicy: "network-only",
    });
    return response.data.MKP_Tarif;
  },

  async getAllFamilleTarif({ }, payload) {
    let response = await apolloClient.query({
      query: require("@/graphql/Tarifs/get_tarifs_famille_structure.gql"),
      variables: {
        ID_Structure: payload.ID_Structure,
      },
    });

    return response.data.MKP_TarifFamille;
  },

  async updateAffiliationTarifSructure({ }, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Tarifs/insert_tarif_affiliation_structure.gql"),
      variables: {
        tarif: payload.tarif,
      },
    });

    return response;
  },
  async deleteAffiliationTarif({ }, payload) {
    return await apolloClient.mutate({
      mutation: require("@/graphql/Tarifs/delete_tarif_affiliation_structure.gql"),
      variables: {
        ID_Tarif: payload.ID_Tarif,
      },
      update: (store, { data: { delete_MKP_Tarif } }) => {
        let query = {
          query: require("@/graphql/Tarifs/get_tarifs_affiliation_structure.gql"),
          variables: {
            ID_Structure: delete_MKP_Tarif.returning[0].ID_Structure,
          },
        };
        const data = store.readQuery(query);
        let item = data.MKP_Tarif.find(
          (tarif) => tarif.id == delete_MKP_Tarif.returning[0].id
        );
        if (item) {
          data.MKP_Tarif.splice(data.MKP_Tarif.indexOf(item), 1);
        }

        store.writeQuery({ ...query, data: data });
      },
    });
  },

  async getTempAffiliation({ }, payload) {
    let query = {
      query: require("@/graphql/Structures/get_temp_affiliation_by_id.gql"),
      variables: {
        id: payload.id,
      },
      fetchPolicy: "network-only",
    };
    return await apolloClient
      .query(query)
      .then((response) => {
        return response.data.STR_AffiliationTemp;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  async saveTempAffiliation({ }, payload) {
    console.log(payload.affiliation);
    let mutation = {
      mutation: require("@/graphql/Structures/insert_temp_affiliation.gql"),
      variables: { affiliation: payload.affiliation },
    };
    return await apolloClient
      .mutate(mutation)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  async editCommentaireTempAffiliation({ }, payload) {
    console.log(payload.affiliation);
    let mutation = {
      mutation: require("@/graphql/Structures/edit_commentaire_temp_affiliation.gql"),
      variables: { affiliation: payload.affiliation },
    };
    return await apolloClient
      .mutate(mutation)
      .then((response) => {
        console.log("response", response);
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  async getAffiliationsTemp({ }, payload) {
    let response = await apolloClient.query({
      query: require("@/graphql/Structures/get_temp_affiliations.gql"),
      fetchPolicy: "network-only",
    });

    return response.data;
  },

  async getAffiliationsTempByCt({ }, payload) {
    let response = await apolloClient.query({
      query: require("@/graphql/Structures/get_temp_affiliations_by_ct.gql"),
      variables: {
        ID_Departement: payload,
      },
      fetchPolicy: "network-only",
    });

    return response.data;
  },

  async getSingleAffiliationTemp({ }, payload) {
    let response = await apolloClient.query({
      query: require("@/graphql/Structures/get_temp_affiliation_single.gql"),
      variables: {
        ID_Affiliation: payload.ID_AffiliationTemp,
      },
      fetchPolicy: "network-only",
    });

    return response.data;
  },

  async updateStatusAffiliationTemp({ }, payload) {
    let mutation = {
      mutation: require("@/graphql/Structures/edit_affiliation_temp_status.gql"),
      variables: { affiliation: payload.affiliation },
    };
    return await apolloClient
      .mutate(mutation)
      .then((response) => {
        console.log("response", response);
        return response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  async deleteTempAffiliation({ }, payload) {
    let mutation = {
      mutation: require("@/graphql/Structures/delete_affiliation_temp.gql"),
      variables: { id: payload },
    };
    return await apolloClient
      .mutate(mutation)
      .then(() => {
        return true
      })
      .catch((e) => {
        throw e;
      });
  },

  async addUserToStructureOnPrimoAffi({ }, payload) {
    console.log("payload :>> ", payload);
    return await apolloClient.mutate({
      mutation: require("@/graphql/Structures/add_users_to_structure_on_primo_affi.gql"),
      variables: {
        user: payload.user,
      },
    });
  },
};

export { actions, mutations };
